








































import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class PageShareHunch extends Vue {
  @Getter("hunch/shared") hunch!: SharedHunch;

  private getLink(dot: Dot): string {
    if (dot.media) {
      return dot.media.path;
    }

    return "#";
  }
}
